export default [
  {
    header: 'Blogs',
  },
  {
    title: 'Blog',
    children: [
      {
        title: 'Lista de Posts',
        route: 'pages-blog-list',
      },
      {
        title: 'Detail',
        route: { name: 'pages-blog-detail', params: { id: 1 } },
      },
      {
        title: 'Edit',
        route: { name: 'pages-blog-edit', params: { id: 1 } },
      },
    ],
  },
]
