export default [
  {
    header: 'Página Web',
  },
  {
    title: 'Paginas',
    icon: 'FileIcon',
    children: [
      {
        title: 'Inicio',
        icon: 'CircleIcon',
        children: [
          {
            title: 'Banners',
            route: 'auth-login-v1',
            target: '_blank',
          },
          {
            title: 'Anúncios en Banner',
            route: 'auth-login-v2',
            target: '_blank',
          },
          {
            title: 'Cursos Inicio',
            route: 'auth-register-v1',
            target: '_blank',
          },
          {
            title: 'Cursos Programados',
            route: 'auth-register-v2',
            target: '_blank',
          },
          {
            title: 'Información',
            route: 'auth-forgot-password-v1',
            target: '_blank',
          },
        ],
      },
      {
        title: 'Seccion Cursos',
        route: 'pages-account-setting',
      },
      {
        title: 'Sección Blog',
        route: 'pages-profile',
      },
      {
        title: 'Preguntas y Respuestas',
        route: 'pages-faq',
      },
    ],
  },
]
