export default [
  {
    header: 'Seguridad',
  },
  {
    title: 'Gestión Usuario',
    icon: 'UserIcon',
    children: [
      {
        title: 'Usuarios',
        route: 'apps-users-list',
      },
      {
        title: 'View',
        route: { name: 'apps-users-view', params: { id: 21 } },
      },
      {
        title: 'Edit',
        route: { name: 'apps-users-edit', params: { id: 21 } },
      },
    ],
  },
  {
    title: 'Gestión Roles',
    icon: 'UserIcon',
    children: [
      {
        title: 'Permisos',
        route: 'apps-roles-list',
      },
      {
        title: 'View',
        route: { name: 'apps-roles-view', params: { id: 21 } },
      },
      {
        title: 'Edit',
        route: { name: 'apps-roles-edit', params: { id: 21 } },
      },
    ],
  },
  /* {
    title: 'eCommerce',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Shop',
        route: 'apps-e-commerce-shop',
      },
      {
        title: 'Details',
        route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
      },
      {
        title: 'Wishlist',
        route: 'apps-e-commerce-wishlist',
      },
      {
        title: 'Checkout',
        route: 'apps-e-commerce-checkout',
      },
    ],
  },
  */
]
