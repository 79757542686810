import dashboard from './dashboard'
import cursosYCapacitaciones from './cursos-y-capacitaciones'
import usuarios from './usuarios'
import paginaWeb from './pagina-web'
import blog from './blog'

export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  /* {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
    action: 'create',
    resource: 'Curso',
  }, */
  ...dashboard,
  ...usuarios,
  ...cursosYCapacitaciones,
  ...blog,
  ...paginaWeb,
]
