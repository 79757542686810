export default [
  {
    header: 'Cursos & Capacitaciones',
  },
  {
    title: 'Cursos',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Curso',
        route: 'curso-list',
      },
      {
        title: 'Cursos Lanzados',
        route: 'curso-versiones-list',
      },
      {
        title: 'Lanzar Version',
        route: { name: 'curso-version-add' },
      },
    ],
  },
  {
    title: 'Categorias',
    icon: 'GridIcon',
    route: 'categoria-list',
  },
  {
    title: 'Carreras',
    icon: 'PackageIcon',
    route: 'carrera-list',
  },
  {
    title: 'Otros',
    icon: 'HardDriveIcon',
    children: [
      {
        title: 'Pais',
        icon: 'TagIcon',
        route: 'pais',
      },
      {
        title: 'Faq',
        icon: 'HelpCircleIcon',
        route: 'faq',
      },
      {
        title: 'Beneficios',
        icon: 'SunriseIcon',
        route: 'beneficio',
      },
      {
        title: 'Tags',
        icon: 'TagIcon',
        route: 'tag',
      },
      {
        title: 'Modalidad',
        icon: 'TagIcon',
        route: 'modalidad',
      },
      {
        title: 'Metodo Pago',
        icon: 'TagIcon',
        route: 'metodo-pago',
      },
    ],
  },
]
